<template>
  <v-container fluid>

    <!-- START DATATABLE -->
    <v-row class="pa-3">
        <v-data-table
            v-model="selected"
            :headers="headers"
            :items="filterIndexes"
            :items-per-page="10"
            class="elevation-1"
            :search="search"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            show-select
            :loading="indexLoading"
           
            @select-all="selectAll"
        >
            <template v-for="(col, index) in filters" v-slot:[`header.${index}`]="{ header }">
                {{ header.text }}
                <v-menu :key="index" offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon small :color="filters[header.value].length ? 'red': ''">
                                mdi-filter-variant
                            </v-icon>
                        </v-btn>
                    </template>
                    <div style="background-color: white; width: 280px">
                        <v-list>
                            <v-list-item>
                                <div v-if="filters.hasOwnProperty(header.value)">
                                    <v-autocomplete
                                        multiple
                                        dense
                                        clearable
                                        chips
                                        small-chips
                                        color="light-blue lighten-3"
                                        :items="columnValueList(header.value)"
                                        append-icon="mdi-filter"
                                        v-model="filters[header.value]"
                                        :label="filters[header.value] ? `filter by: ${header.text}` : ''"
                                        hide-details
                                    >
                                        <template v-slot:selection="{ item, index }">
                                            <v-chip small class="caption" v-if="index < 5">
                                                <span>{{ item }}</span>
                                            </v-chip>
                                            <span v-if="index === 5" class="grey--text caption">
                                                (+{{ filters[header.value].length - 5}} others)
                                            </span>
                                        </template>
                                    </v-autocomplete>
                                </div>
                            </v-list-item>
                        </v-list>
                    </div>
                </v-menu>
            </template>
            <template v-slot:item.TypeOfAnimal="{ item }">
              {{ splitSpecies(item.TypeOfAnimal)}}
            </template>
            <template v-slot:header="{ props: { headers } }">
                <thead>
                    <tr>
                        <th :colspan="headers.length">
                          <v-btn class="ma-1" color="guacamole" @click.prevent="exportData">
                            <span v-if="!loading">Export</span>
                            <v-progress-circular indeterminate v-else />
                          </v-btn>
                          <v-btn class="ma-1" color="blue" @click.prevent="exportSelect" :disabled="haveSelection">
                            <span v-if="!loadingSel">Export Sel</span>
                            <v-progress-circular indeterminate v-else />
                          </v-btn>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-toolbar-title>Incidents in {{ country }}</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    />
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="viewItem(item)">
                    mdi-eye
                </v-icon>
                <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                </v-icon>
                <v-icon small @click="getConfirm(item)">
                    mdi-delete
                </v-icon>
            </template>
            <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">Reset</v-btn>
            </template>
        </v-data-table>
    </v-row>
    <!-- END DATATABLE -->


    <!-- START - CONFIRM DELETE DIALOG -->
    <v-dialog
        v-model="deletedialog"
        persistent
        width="350"
        :ripple="foo"
        style="z-index: 1301"
    >
        <v-card>
            <v-toolbar color="warning" class="justify-center mb-2 rounded-0">
                <v-toolbar-title>Confirm Incident Delete</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <p>You are about to delete an Incident. If you are sure,
                    type the following string:</p>
                <p class="justify-center text-center" style="background-color: #e6e6e6">
                    <pre>{{ todelete }}</pre>
                </p>
                <p>in the text input below and press confirm.</p>
                <v-form
                    v-model="valid"
                    ref="confirmform"
                    lazy-validation
                > 
                    <v-text-field
                        v-model="deletecode"
                        :rules="confirmRule"
                        @keyup.enter="delconfirm"
                    />
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="error" @click.stop="delcancel">Cancel</v-btn>
                <v-btn color="warning" @click.stop="delconfirm">Confirm</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- END - CONFIRM DELETE DIALOG -->

    <v-divider class="ma-4"/>
    <last-modified-by-user />
    <activity name="User" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { get } from "vuex-pathify";

// Datetime experiments
import { DateTime } from "luxon";

import ExportFileService from '@/services/ExportFileService'
import LastModified from '@/components/LastModifiedByUser'
import Activity from '@/components/Activity'

export default {
    components: {
        'last-modified-by-user': LastModified,
        'activity': Activity
    },
  data() {
    return {
      // desserts: [],
      // START DATATABLE DATA
      selected: [],
      filters: {region: [], TypeOfAnimal: [], poisonBaitFound: [], autopsyAndMore: [], antipoisonDogUnitUsed: []},
      search: '',
      loading: false,
      loadingSel: false,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      sortBy: 'entrycode',
      sortDesc: true,
      // END DATATABLE DATA
      // START DELETE DIALOG
      valid: false,
      deletedialog: false,
      foo: false,
      todelete: "",
      deluserid: "",
      deletecode: ""
      // END DELETE DIALOG
    };
  },
  computed: {
      ...get('newauth', [
        'userProfile@country',
        'userProfile@is_superuser'
      ]),
      ...mapGetters({
        // getIndexes: "incidents/getIndexesByCountry",
        // indexLoading: "incidents/indexLoading"
        getIndexes: "incidentsnew/getIndexesByCountry",
        indexLoading: "incidentsnew/indexLoading"
      }),
      indexes () {
        let tmpindex = this.getIndexes(this.country)
        return tmpindex
      },
      superadmin: get('newauth@userProfile.is_superuser'),
      headers () {
          return [
              {
                  text: 'EntryCode',
                  align: 'start',
                  sortable: true,
                  value: 'entrycode'
              },
              {
                  text: "Notification Date",
                  value: "dateOfEvent",
                  sortable: true,
                  sort: (a, b) => {
                    let _a = a
                    let _b = b
                    if (!a) {
                      _a = '01/01/1900'
                    }
                    if (!b) {
                      _b = '01/01/1900'
                    }
                    return DateTime.fromFormat(_a, "dd/MM/yyyy") - DateTime.fromFormat(_b, "dd/MM/yyyy")
                  }
              },
              {
                  text: "Country",
                  value: "country"
              },
              {
                  text: "Region",
                  value: "region"
              },
              {
                  text: "Municipality",
                  value: "municipality"
              },
              {
                  text: "Species found dead",
                  value: "TypeOfAnimal"
              },
              {
                  text: "Poison bait found",
                  value: "poisonBaitFound"
              },
              {
                  text: "Autopsy and/or tox. analyses carried out",
                  value: "autopsyAndMore"
              },
              {
                  text: "Antipoison Dog Unit used",
                  value: "antipoisonDogUnitUsed"
              },
              {
                  text: "Live",
                  value: "live"
              },
              {
                  text: "Actions",
                  value: 'actions',
                  sortable: false
              }
          ]
      },
      filterIndexes () {
          this.indexes.forEach(item => {
              if (!item.country) {
                  item.country = 'n/a'
              }
              if (!item.region) {
                  item.region = 'n/a'
              }
              if (!item.TypeOfAnimal) {
                  item.TypeOfAnimal = 'n/a'
              }
              if (!item.poisonBaitFound) {
                  item.poisonBaitFound = 'n/a'
              }
              if (!item.autopsyAndMore) {
                  item.autopsyAndMore = 'n/a'
              }
              if (!item.antipoisonDogUnitUsed) {
                  item.antipoisonDogUnitUsed = 'n/a'
              }
          })
          return this.indexes.filter((d) => {
              return Object.keys(this.filters).every((f) => {
                  return this.filters[f].length < 1 || this.filters[f].includes(d[f])
              })
          })
      },
      confirmRule() {
        return [
            () => (this.todelete === this.deletecode) || 'Value does not match requested code',
            v => !!v || 'Fill value to confirm'
        ];
      },
      haveSelection () {
        // if (this.selected.length == 0 ) {
        //     return true
        // } else {
        //     return false
        // }
        return this.selected.length == 0 ? true : false
      }
  },
  methods: {
    initialize () {
        // this.desserts = this.indexes
    },
    columnValueList(val) {
        return this.indexes.map((d) => d[val])
    },
    viewItem (item) {
        this.$router.push({name: 'ViewIncident', params: {'entrycode': item.entrycode }})
    },
    editItem (item) {
        this.$router.push({name: 'Informant', params: {'entrycode': item.entrycode}})
    },
    // START CONFIRM DELETE METHODS
    getConfirm (item) {
        this.todelete = `delete ${item.entrycode}`
        this.delentrycode = item.entrycode
        this.deletedialog = true
    },
    delcancel () {
        this.$refs.confirmform.resetValidation()
        this.deletecode = ""
        this.todelete= ""
        this.delentrycode = ""
        this.deletedialog = false
    },
    delconfirm () {
        if (this.$refs.confirmform.validate()) {
            this.deletecode = ""
            // delete the user
            this.$store.dispatch('incidentsnew/deleteIncident', this.delentrycode)
                .then(console.log(true))
                .catch(e => {
                    console.log(e)
                })
        } else {
            this.foo = true
            console.log('the validation is not correct')
        }
        this.deletecode = ""
        this.delentrycode = ""
        this.$refs.confirmform.resetValidation()
        this.deletedialog = false
    },
    selectAll(items) {
        // console.log('this is called as well, selectAll')
        if (this.selected.length === 0) {
            this.selected = items.slice();
            console.log('this is within the if')
        } else {
            console.log('this is inside the else')
            this.selected = []
        }
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    splitSpecies (item) {
        if(!item) return 'n/a'
        if (item == '#') {
          return 'n/a'
        }
        return item.split('#').join(', ')
    },
    // END CONFIRM DELETE METHODS
    async exportData () {
      this.loading = true
      ExportFileService.exportCountry(this.$store.state.newauth.token).then((res) => {
        var FILE = window.URL.createObjectURL(
          new Blob([res.data],
          { type: 'application/vnd.ms-excel'}
        ));
        this.loading = false;
        var docUrl = document.createElement('a');
        docUrl.href = FILE;
        docUrl.setAttribute('download', `export-${this.country}-${new Date().toLocaleDateString()}.xlsx`);
        document.body.appendChild(docUrl);
        docUrl.click();
      }).catch((error) => {
        console.log(error)
      })
    },
    async exportSelect () {
        this.loadingSel = true
        let selection = this.selected.map((item) => { return item.entrycode})
        ExportFileService.exportSelection(selection, this.$store.state.newauth.token).then((res) => {
            var FILE = window.URL.createObjectURL(
                new Blob([res.data],
                { type: 'application/vnd.ms-excel'})
            )
            this.loadingSel = false;
            var docUrl = document.createElement('a');
            docUrl.href = FILE;
            docUrl.setAttribute('download', `export-selection-${new Date().toLocaleDateString()}.xlsx`);
            document.body.appendChild(docUrl)
            docUrl.click();
        }).catch((error) => {
            console.log(error)
        })
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    }
  },
  created () {
    //   this.initialize()
  }
};
</script>


<style scoped>
</style>