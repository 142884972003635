<template>
        <v-card class="mt-4">
            <v-card-title>
                Last Modified Incidents by: {{ name }}
            </v-card-title>
            <v-data-table :headers="headers" :items="indexes" :items-per-page="15" class="elevation-1">
            <template v-slot:item.modified="{ item }">
                {{ modifiedFormat(item) }}
            </template>
            <template v-slot:item.TypeOfAnimal="{ item }">
                {{ splitSpecies(item.TypeOfAnimal) }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                </v-icon>
                <v-icon small class="mr-2" @click="viewItem(item)">
                    mdi-eye
                </v-icon>
            </template>
            </v-data-table>
        </v-card>
</template>
  
<script>
import { get } from 'vuex-pathify'
import { mapGetters } from "vuex";
import { DateTime } from 'luxon';


export default {
    name: "LastModified",
  data () {
    return {}
  },

  computed: {
    ...mapGetters({
      indexes: "incidentsnew/getLastModifiedByUser",
    }),
    ...get("newauth", {
      "name": "userProfile@email"
    }),
    
    headers () {
      return [
        {
          text: 'EntryCode',
          align: 'start',
          sortable: true,
          value: 'entrycode',
        },
        {
          text: "Notification Date",
          value: "dateOfEvent",
        },
        { text: 'Country', value: 'country',
        },
        { text: 'Region', value: 'region',
        },
        {
          text: "Municipality",
          value: "municipality",
        },
        {
          text: "Species found dead",
          value: "TypeOfAnimal",
        },
        {
          text: "Poison bait found",
          value: "poisonBaitFound",
        },
        {
          text: "Autopsy and/or tox. analyses carried out",
          value: "autopsyAndMore",
        },
        {
          text: "Antipoison Dog Unit used",
          value: "antipoisonDogUnitUsed",
        },
        {
          text: "Live",
          value: "live",
        },
        {
            text: "Modified",
            value: "modified"
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ]
    },
  },

  watch: {},

  created () {
    this.$store.dispatch('incidentsnew/fetchLastModifiedByUser')
  },
  mounted() {
  },

  methods: {
      editItem (item) {
        this.$router.push({name: 'Informant', params: {'entrycode': item.entrycode }})
      },
      viewItem (item) {
        this.$router.push({name: 'ViewIncident', params: {'entrycode': item.entrycode }})
      },
      modifiedFormat (item) {
        return DateTime.fromISO(item.modified).toFormat('dd LLL yyyy - HH:mm:ss')
      },
      splitSpecies (item) {
        if(!item) return 'n/a'
        if (item == '#') {
          return 'n/a'
        }
        return item.split('#').join(', ')
      },
  },
}
</script>


<style scoped>
</style>